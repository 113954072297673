import React from 'react'

import SEO from '../../components/seo'

import Layout from '../../components/layout'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import Intro from '../../components/Intro/Intro'
import List from '../../components/List/List'
import Technologies from '../../components/Technologies/Technologies'
import WorkWithUs from '../../components/WorkWithUs/WorkWithUs'
import BottomNav from '../../components/BottomNav/BottomNav'
import ClientPerks from '../../components/ClientPerks/ClientPerks'
import Testimonial from '../../components/Testimonial/Testimonial'
import WeAre from '../../components/WeAre/WeAre'

const IndexPage = () => {
  const supportProcess = [
    {
      id: 1,
      text: 'You get our developer(s) as a full-blown team member.',
    },
    {
      id: 2,
      text: 'We help you with project management.',
    },
    {
      id: 3,
      text: 'Client meetings ? - Save time. We take them over.',
    },
    {
      id: 4,
      text: 'On-time result delivery.',
    },
    {
      id: 5,
      text: 'We work until you’re satisfied.',
    },
  ]
  return (
    <Layout>
      <SEO
        title="Proactive Developers"
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `London`,
          `digital agency`,
        ]}
      />
      <SectionHeader>Proactive Developers </SectionHeader>
      <Intro
        paragraph1="So, you have a biggy project but not enough manpower to deliver a great end-product on-time?"
        paragraph2="Here’s how we can give you a helping hand."
      />
      <List data={supportProcess} additionalClass="list-section--bg" />{' '}
      <ClientPerks> </ClientPerks>{' '}
      <Testimonial
        paragraph="I am endlessly greatful for the support these guys provide to me.
    We 've been working for 2 years now and I am absolutely looking
    forward to
    continue our work together.
    "
        projectTitle="Radium"
        author="Faisal Hussain"
        authorRole="CEO Radium"
        link="/our-work/radium"
      />{' '}
      <Technologies> </Technologies>{' '}
      <WeAre
        heading="Join Your Team"
        img="https://cdn.emersoft.co/emersoft-website/hire+developers/marcin-ruman-toronto.jpg"
        sideText="We will"
        isLong
      >
        You own the projects, and our thinking outside the box developers become
        a part of your team.{' '}
      </WeAre>{' '}
      <WeAre
        heading="Help You Grow"
        isReverse="true"
        img="https://cdn.emersoft.co/emersoft-website/hire+developers/work-pawel-malaga.jpg"
        sideText="We will"
        isLong
      >
        Scale your team as needed. We bill on a monthly basis.{' '}
      </WeAre>{' '}
      <WorkWithUs isDevelopers />{' '}
      <BottomNav
        firstLink="/services/websites"
        firstHeading="Websites/Design"
        secondLink="/our-work/radium"
        secondHeading="Radium"
      />{' '}
    </Layout>
  )
}

export default IndexPage
